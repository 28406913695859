<template>
  <div>
    <b-card v-if='!isDataLoaded'>
      <div class='text-center text-success my-2'>
        <b-spinner class='align-middle' />
      </div>
    </b-card>

    <div v-else>
      <b-card v-if='details'>
        <h2 class='mb-2 text-primary'>
          {{ $t('general_information') }}
        </h2>
        <b-row>
          <b-col
            md='6'
            lg='3'
            cols='12'
          >
            <h5 class='mb-1'>
              {{ $t('name en') }}
            </h5>
            <p>
              {{ details.name_value && details.name_value.en || '-' }}
            </p>
            <hr>
          </b-col>
          <b-col
            md='6'
            lg='3'
            cols='12'
          >
            <h5 class='mb-1'>
              {{ $t('name ar') }}
            </h5>
            <p>
              {{ details.name_value && details.name_value.ar || '-' }}
            </p>
            <hr>
          </b-col>
          <b-col
            md='6'
            lg='3'
            cols='12'
          >
            <h5 class='mb-1'>
              {{ $t('city') }}
            </h5>
            <p>
              {{ details.city && details.city.name || '-' }}
            </p>
            <hr>
          </b-col>
          <b-col
            md='6'
            lg='3'
            cols='12'
          >
            <h5 class='mb-1'>
              {{ $t('labels.district type') }}
            </h5>
            <p>
              {{ $t(details.type) || '-' }}
            </p>
            <hr>
          </b-col>
          <b-col
            md='6'
            lg='3'
            cols='12'
          >
            <h5 class='mb-1'>
              {{ $t('labels.The neighborhood appears in the area guide') }}
            </h5>
            <p>
              {{ details.show_area_directory ? $t('yes') : $t('no') || '-' }}
            </p>
            <hr>
          </b-col>
          <b-col
            md='6'
            lg='3'
            cols='12'
          >
            <h5 class='mb-1'>
              {{ $t('labels.Keywords') }}
            </h5>
            <p
              v-if='details.keywords && details.keywords.length > 0'
              style='gap: 10px'
              class='d-flex'
            >
            <span
              v-for='(el, index) in details.keywords'
              :key='index'
            >
              <b-badge
                pill
                variant='light-primary'
                style='min-width: 50px;'
              >{{ el }}</b-badge>
            </span>
            </p>
            <p v-else>
              -
            </p>
            <hr>
          </b-col>
          <b-col
            md='6'
            lg='3'
            cols='12'
          >
            <h5 class='mb-1'>
              {{ $t('labels.district rating') }}
            </h5>
            <b-form-rating
              id='rating-md'
              v-model='details.rating'
              disabled
              no-border
              variant='primary'
              class='w-100 p-0 mt-2'
              style='height: max-content'
            />
            <hr>
          </b-col>
          <b-col
            md='6'
            lg='3'
            cols='12'
          >
            <h5 class='mb-1'>
              {{ $t('selling_meter_price') }}
            </h5>
            <p>
              {{ details.selling_meter_price || '-' }}
            </p>
            <hr>
          </b-col>
        </b-row>
      </b-card>
      <b-card v-if='details'>
        <h2 class='mb-2 text-primary'>
          {{ $t('geographical_location') }}
        </h2>
        <b-row>
          <b-col
            md='6'
            cols='12'
          >
            <h5 class='mb-1'>
              {{ $t('latitude_coordinates') }}
            </h5>
            <p>
              {{ details.lat || '-' }}
            </p>
            <hr>
          </b-col>
          <b-col
            md='6'
            cols='12'
          >
            <h5 class='mb-1'>
              {{ $t('longitude_coordinates') }}
            </h5>
            <p>
              {{ details.lng || '-' }}
            </p>
            <hr>
          </b-col>
        </b-row>
      </b-card>
      <b-card v-if='details'>
        <h2 class='mb-2 text-primary'>
          {{ $t('description') }}
        </h2>
        <b-row>
          <b-col
            cols='12'
            md='6'
          >
            <h5 class='mb-1'>
              {{ $t('labels.basic_information') }}
            </h5>
            <p
              v-if='details.basic_information_value && details.basic_information_value[$i18n.locale]'
              v-html='details.basic_information_value[$i18n.locale]'
            />
            <p v-else>
              -
            </p>
            <hr>
          </b-col>
          <b-col
            cols='12'
            md='6'
          >
            <h5 class='mb-1'>
              {{ $t('labels.overview_real_estate') }}
            </h5>
            <p
              v-if='details.overview_real_estate_value && details.overview_real_estate_value[$i18n.locale]'
              v-html='details.overview_real_estate_value[$i18n.locale]'
            />
            <p v-else>
              -
            </p>
            <hr>
          </b-col>
          <b-col
            cols='12'
            md='6'
          >
            <h5 class='mb-1'>
              {{ $t('labels.rent_price_indicator') }}
            </h5>
            <p
              v-if='details.rent_price_indicator_value && details.rent_price_indicator_value[$i18n.locale]'
              v-html='details.rent_price_indicator_value[$i18n.locale]'
            />
            <p v-else>
              -
            </p>
            <hr>
          </b-col>
          <b-col
            cols='12'
            md='6'
          >
            <h5 class='mb-1'>
              {{ $t('labels.sale_price_indicator') }}
            </h5>
            <p
              v-if='details.rent_price_indicator_value && details.rent_price_indicator_value[$i18n.locale]'
              v-html='details.rent_price_indicator_value[$i18n.locale]'
            />
            <p v-else>
              -
            </p>
            <hr>
          </b-col>
          <b-col
            cols='12'
            md='6'
          >
            <h5 class='mb-1'>
              {{ $t('labels.considerations_keep_mind') }}
            </h5>
            <p
              v-if='details.considerations_keep_mind_value && details.considerations_keep_mind_value[$i18n.locale]'
              v-html='details.considerations_keep_mind_value[$i18n.locale]'
            />
            <p v-else>
              -
            </p>
            <hr>
          </b-col>
          <b-col
            cols='12'
            md='6'
          >
            <h5 class='mb-1'>
              {{ $t('labels.Frequently asked questions about the neighborhood') }}
            </h5>
            <p
              v-if='details.faq_value && details.faq_value[$i18n.locale]'
              v-html='details.faq_value[$i18n.locale]'
            />
            <p v-else>
              -
            </p>
            <hr>
          </b-col>
          <b-col
            cols='12'
            md='6'
          >
            <h5 class='mb-1'>
              {{ $t('labels.main_landmark_locations') }}
            </h5>
            <p
              v-if='details.main_landmark_locations_value && details.main_landmark_locations_value[$i18n.locale]'
              v-html='details.main_landmark_locations_value[$i18n.locale]'
            />
            <p v-else>
              -
            </p>
            <hr>
          </b-col>
        </b-row>
      </b-card>
      <b-card v-if='details'>
        <h2 class='mb-2 text-primary'>
          {{ $t('attachments') }}
        </h2>
        <b-row>
          <b-col
            cols='12'
          >
            <h5 class='mb-1'>
              {{ $t('main_image') }}
            </h5>
            <div class='grid-images full-row-width'>
              <div>
                <img
                  :src='details.main_image'
                  alt='Image'
                >
              </div>
            </div>
            <hr>
          </b-col>
          <b-col
            cols='12'
          >
            <h5 class='mb-1'>
              {{ $t('images') }}
            </h5>
            <div class='grid-images'>
              <div
                v-for='(image, index) in details.images'
                :key='index'
              >
                <img
                  :src='image.original_url'
                  alt='Image'
                >
              </div>
            </div>
            <hr>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BFormRating } from 'bootstrap-vue'

export default {
  components: { BFormRating },
  data() {
    return {
      details: null,
      isDataLoaded: false,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.axios.get(`/districts/${this.$route.params.resourceId}?is_edit=true`)
        .then(res => {
          this.details = res.data.data
          this.isDataLoaded = true
        }).catch(error => {
        this.isDataLoaded = true
      })
    },
  },
}
</script>
<style scoped lang='scss'>
h5 {
  color: #00cfe8
}

.grid-images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }

  > div {
    height: 350px;
    border-radius: 12px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }

  &.full-row-width {
    grid-template-columns: repeat(1, 1fr);

    > div {
      height: 450px;
    }
  }
}
</style>
